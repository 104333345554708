import { extendTheme } from 'native-base';

import Colors from '@/constants/Colors';

export const customTheme = extendTheme({
  shadows: {
    2: {
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.2,
      shadowRadius: 16,
      elevation: 3,
    },
  },
  colors: {
    'mg.bg': Colors.background,
    'mg.borderColor': Colors.borderColor,
    'mg.dark.100': Colors.disabledDark,
    'mg.dark.200': Colors.secondaryDark,
    'mg.dark.300': Colors.primaryDark,
    'mg.gray.100': Colors.gray,
    'mg.gray.200': Colors.darkGray,
    'mg.green.100': Colors.secondaryGreen,
    'mg.green.200': Colors.primaryGreen,
    'mg.green.300': Colors.darkGreen,
    'mg.red.800': Colors.red,
  },
  components: {
    Actionsheet: {
      baseStyle: {
        color: 'mg.dark.300',
      },
    },
    Button: {
      defaultProps: {
        size: 'lg',
      },
      baseStyle: {
        rounded: 'full',
      },
      variants: {
        primary: {
          bg: 'mg.green.300',
          colorSchema: 'green',
        },
        secondary: {
          bg: 'mg.gray.200',
          colorSchema: 'gray',
        },
      },
    },
    Select: {
      baseStyle: {
        p: 3,
        fontSize: 'md',
        bg: 'contrastThreshold',
        color: 'mg.dark.300',
      },
      customDropdownIconProps: {
        size: '12',
        color: 'mg.dark.300',
      },
    },
    Text: {
      baseStyle: {
        color: 'mg.dark.300',
        fontSize: 'md',
      },
      sizes: {
        xxs: { fontSize: '10px' },
      },
    },
    Heading: {
      sizes: {
        xxs: { fontSize: '10px' },
      },
      baseStyle: {
        fontWeight: '400',
      },
    },
    Input: {
      defaultProps: {
        color: 'mg.dark.200',
      },
    },
  },
  fontConfig: {
    Lato: {
      100: {
        normal: 'Lato_100Thin',
        italic: 'Lato_100Thin_Italic',
      },
      200: {
        normal: 'Lato_100Thin',
        italic: 'Lato_100Thin_Italic',
      },
      300: {
        normal: 'Lato_300Light',
        italic: 'Lato_300Light_Italic',
      },
      400: {
        normal: 'Lato_400Regular',
        italic: 'Lato_400Regular_Italic',
      },
      500: {
        normal: 'Lato_700Bold',
        italic: 'Lato_700Bold_Italic',
      },
      600: {
        normal: 'Lato_900Black',
        italic: 'Lato_900Black_Italic',
      },
    },
  },
  fonts: {
    heading: 'Lato',
    body: 'Lato',
    mono: 'Lato',
  },
});

export type CustomThemeType = typeof customTheme;
